import { ScreenFragment } from 'entities/assessment'
import { fabric } from 'fabric'

interface LoadImageParams {
  imageUrl: string
  setImage: (value: fabric.Image) => void
}

export const loadImage = ({ imageUrl, setImage }: LoadImageParams) => {
  fabric.Image.fromURL(imageUrl, (oImg) => {
    oImg.set('selectable', false)
    oImg.set('objectCaching', false)
    setImage(oImg)
  })
}

interface DrawReportCanvasParams {
  canvas: fabric.Canvas
  fragment: ScreenFragment
}

export const drawReportCanvas = ({
  canvas,
  fragment,
}: DrawReportCanvasParams) => {
  const imgElement = document.getElementById('canvas-image')

  if (imgElement) {
    const imgInstance = new fabric.Image(imgElement as any, {
      left: 0,
      top: 0,
      selectable: false,
    })
    canvas.add(imgInstance)
  }

  if (fragment.height && fragment.width) {
    const topMaskRect = new fabric.Rect({
      left: 0,
      top: 0,
      originX: 'left',
      originY: 'top',
      width: canvas.width,
      height: fragment.position.y,
      fill: 'rgba(0, 0, 0, 0.5)',
      transparentCorners: false,
      selectable: false,
      hasBorders: false,
    })

    const bottomMaskRect = new fabric.Rect({
      left: 0,
      top: fragment.position.y + fragment.height,
      originX: 'left',
      originY: 'top',
      width: canvas.width,
      height: Number(canvas.height) - fragment.position.y,
      fill: 'rgba(0, 0, 0, 0.5)',
      transparentCorners: false,
      selectable: false,
      hasBorders: false,
    })

    const leftMaskRect = new fabric.Rect({
      left: 0,
      top: fragment.position.y,
      originX: 'left',
      originY: 'top',
      width: fragment.position.x,
      height: fragment.height,
      fill: 'rgba(0, 0, 0, 0.5)',
      transparentCorners: false,
      selectable: false,
      hasBorders: false,
    })

    const rightMaskRect = new fabric.Rect({
      left: fragment.position.x + fragment.width,
      top: fragment.position.y,
      originX: 'left',
      originY: 'top',
      width: Number(canvas.width) - fragment.position.x,
      height: fragment.height,
      fill: 'rgba(0, 0, 0, 0.5)',
      transparentCorners: false,
      selectable: false,
      hasBorders: false,
    })

    canvas.add(topMaskRect)
    canvas.add(bottomMaskRect)
    canvas.add(leftMaskRect)
    canvas.add(rightMaskRect)
  }

  canvas.renderAll()
}
