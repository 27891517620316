import { Button } from '@mui/material'
import classNames from 'classnames'

import s from './FindSelectButton.module.css'

interface FindSelectButtonProps {
  selected: boolean
  onClick: VoidFunction
  children: React.ReactNode
  icon: React.ReactNode
  outlined?: boolean
  noGrow?: boolean
  disabled?: boolean
}

export const FindSelectButton = ({
  icon,
  children,
  onClick,
  selected,
  outlined,
  noGrow,
  disabled,
}: FindSelectButtonProps) => {
  const defaultVariant = outlined ? 'outlined' : 'text'
  const variant = selected ? 'contained' : defaultVariant

  return (
    <Button
      className={classNames(s.button, {
        [s.buttonContained]: selected,
        [s.outlined]: variant === 'outlined',
        [s.noGrow]: noGrow,
        [s.disabled]: disabled,
        [s.disabledAndNotSelected]: disabled && !selected,
      })}
      variant={variant}
      onClick={onClick}
      startIcon={icon}
    >
      {children}
    </Button>
  )
}
