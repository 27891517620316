import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      ratings: 'Ratings',
      correct: 'Correct',
      incorrect: 'Incorrect',
      relatedMaterials: 'Related materials',
      show: 'Show',
      hide: 'Hide',
      articles: 'Articles',
      videos: 'Videos',
      expertCommentary: 'Expert commentary',
      tags: 'Tags',
      scenario: 'Scenario',
      mainScreen: 'Main screen',
      mainPage: 'Main page',
      saveProjectError: 'An error occurred while saving the project',
      updateProjectError: 'An error occurred while updating the project',
      notSatisfied: 'Not satisfied',
      satisfied: 'Satisfied',
      partially: 'Partially',
      notApplicable: 'Not applicable',
      newFragment: 'New fragment',
      fragments: 'Fragments',
      fetchHeuristicsError: 'An error occurred while retrieving heuristics',
      user: 'User',
      service: 'Service',
      time: 'Time',
      context: 'Context',
      main: 'Main',
      transitionOptions: 'Transition options',
      remove: 'Remove',
      removeAssessment: 'Remove assessment',
      addAssessment: 'Add assessment',
      comment: 'Comment',
      addComment: 'Add comment',
      recommendation: 'Recommendation',
      addRecommendation: 'Add recommendation',
      column: 'column',
      row: 'row',
      name: 'name',
      nameBig: 'Name',
      addColumn: 'Add column',
      removePair: 'Remove pair',
      gptVersion: 'GPT Version',
      addPair: 'Add pair',
      edit: 'Edit',
      existingAction: 'Existing action',
      new: 'New',
      whatDoesItDo: 'What does it do? (user, system or time)',
      whatIsTheInitiator: 'What is the initiator?',
      role: 'Role',
      whereDoesItGo: 'Where does it go?',
      furtherToTheRight: 'Further to the right',
      somewhereElse: 'Somewhere else',
      whatDoYouGetAsAResult: 'What do you get as a result?',
      relatedTransition: 'Related transition',
      cancel: 'Cancel',
      save: 'Save',
      back: 'Back',
      actionRemoving: 'Action removing',
      actionEditing: 'Action editing',
      transitionEditing: 'Transition editing',
      areYouSureToDeleteTheAction: 'Are you sure to delete the action',
      itIsListedInTransitions: 'It is listed in {{number}} transitions',
      transition: 'transition',
      addAction: 'Add action',
      addResult: 'Add result',
      all: 'All',
      toFragmentsList: 'To fragments list',
      newFind: 'New find',
      find: 'Find',
      heuristic: 'Heuristic',
      finds: 'Finds',
      assessments: 'Assessments',
      enterTag: 'Enter tag',
      searchHeuristicsByTags: 'Search heuristics by name and tags',
      searchAssessmentsByTags: 'Search assessments by tags',
      home: 'Home',
      masterScenario: 'Master scenario',
      finalAssessment: 'Final assessment',
      assessment: 'Assessment',
      report: 'Report',
      closePage: 'Close page',
      fragment: 'Fragment',
      findName: 'Find name',
      findHeuristicName: 'Heuristic name will be used',
      findType: 'Find type',
      finalFindType: 'Final find type',
      findDescription: 'Find description',
      description: 'Description',
      criticality: 'Сriticality',
      finalCriticality: 'Final criticality',
      usabilityProblem: 'Usability problem',
      usabilityGood: 'User-friendly',
      businessTask: 'Business task',
      bug: 'Bug',
      low: 'Low',
      medium: 'Medium',
      high: 'High',
      addFind: 'Add find',
      removeFind: 'Remove find',
      add: 'Add',
      saveToTotalAssessment: 'Save to final assessment',
      dontIncludeInTheFinalReport: "Don't include in the final report",
      includeInTheFinalReport: 'Include in the final report',
      completeTheFinalReportBuilding: 'Complete the final report building',
      yourVersion: 'Your version',
      page: 'Page',
      total: 'Total',
      your: 'Your',
      more: 'More',
    },
  },
  ru: {
    translation: {
      ratings: 'Оценок',
      correct: 'Правильно',
      incorrect: 'Неправильно',
      relatedMaterials: 'Материалы по теме',
      show: 'Показать',
      hide: 'Скрыть',
      articles: 'Статьи',
      videos: 'Видео',
      expertCommentary: 'Комментарий эксперта',
      tags: 'Теги',
      scenario: 'Сценарий',
      mainScreen: 'Основной экран',
      mainPage: 'Основная страница',
      saveProjectError: 'Произошла ошибка при сохранении проекта',
      updateProjectError: 'Произошла ошибка при обновлении проекта',
      notSatisfied: 'Не выполняется',
      satisfied: 'Выполняется',
      partially: 'Частично',
      notApplicable: 'Неприменимо',
      newFragment: 'Новый фрагмент',
      fragments: 'Фрагменты',
      fetchHeuristicsError: 'Произошла ошибка при получении эвристик',
      user: 'Пользователь',
      service: 'Система',
      time: 'Время',
      context: 'Контекст',
      main: 'Основной',
      transitionOptions: 'Опции перехода',
      remove: 'Удалить',
      removeAssessment: 'Удалить оценку',
      addAssessment: 'Добавить оценку',
      comment: 'Комментарий',
      addComment: 'Добавить комментарий',
      recommendation: 'Рекомендация',
      addRecommendation: 'Добавить рекомендацию',
      column: 'столбец',
      row: 'строку',
      name: 'название',
      nameBig: 'Название',
      addColumn: 'Добавить столбец',
      removePair: 'Удалить пару',
      gptVersion: 'Версия GPT',
      addPair: 'Добавить пару',
      edit: 'Редактировать',
      existingAction: 'Существующее действие',
      new: 'Новое',
      whatDoesItDo: 'Что делает? (пользователь, система или время)',
      whatIsTheInitiator: 'Кто инициирует?',
      role: 'Роль',
      whereDoesItGo: 'Куда переходим?',
      furtherToTheRight: 'Дальше вправо',
      somewhereElse: 'Куда-то еще',
      whatDoYouGetAsAResult: 'Что получим в результате?',
      relatedTransition: 'Связанный переход',
      cancel: 'Отменить',
      save: 'Сохранить',
      back: 'Назад',
      actionRemoving: 'Удаление действия',
      actionEditing: 'Редактирование действия',
      transitionEditing: 'Редактирование перехода',
      areYouSureToDeleteTheAction: 'Are you sure to delete the action',
      itIsListedInTransitions: 'Оно указано в {{number}} переходах',
      transition: 'переход',
      addAction: 'Добавить действие',
      addResult: 'Добавить результат',
      all: 'Все',
      toFragmentsList: 'К списку фрагментов',
      newFind: 'Новая находка',
      find: 'Находка',
      heuristic: 'Эвристика',
      finds: 'Находки',
      assessments: 'Оценки',
      enterTag: 'Введите тег',
      searchHeuristicsByTags: 'Поиск эвристик по названию и тегам',
      searchAssessmentsByTags: 'Поиск оценок по тегам',
      home: 'Главная',
      masterScenario: 'Мастер-сценарий',
      finalAssessment: 'Итоговая оценка',
      assessment: 'Оценка',
      report: 'Отчет',
      closePage: 'Закрыть страницу',
      fragment: 'Фрагмент',
      findName: 'Название находки',
      findHeuristicName: 'Будет использовано название эвристики',
      findType: 'Тип находки',
      finalFindType: 'Итоговый тип находки',
      findDescription: 'Описание находки',
      description: 'Описание',
      criticality: 'Критичность',
      finalCriticality: 'Итоговая критичность',
      usabilityProblem: 'Юзабилити проблема',
      usabilityGood: 'Удобный UX',
      businessTask: 'Бизнес-задача',
      bug: 'Баг',
      low: 'Низкая',
      medium: 'Средняя',
      high: 'Высокая',
      addFind: 'Добавить находку',
      removeFind: 'Удалить находку',
      add: 'Добавить',
      saveToTotalAssessment: 'Сохранить в итоговую оценку',
      dontIncludeInTheFinalReport: 'Не включать в итоговый отчет',
      includeInTheFinalReport: 'Включать в итоговый отчет',
      completeTheFinalReportBuilding: 'Завершить сборку итогового отчета',
      yourVersion: 'Свой вариант',
      page: 'Страница',
      total: 'Итого',
      your: 'Ваш',
      more: 'Еще',
    },
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
